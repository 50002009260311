.spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.spinner:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid whitesmoke;
  border-color: pink transparent #38015c transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
