.modalBackground {
  width: 100%;
  height: 50%;
  background-color: rgba(81, 78, 78, 0);
  position: fixed;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.modalContainer {
  border: 2px solid black;
  width: 75%;
  height: 84%;
  border-radius: 12px;
  background-color: #9f5ccc;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 15px 25px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: 10px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  color: black;
  /* margin-top: 100px; */
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 15%;
  height: 60%;
  margin: 10px;
  border: 1px solid black;
  background-color: #38015c;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}
