.button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #38015c;
  border: 1px solid #9f5ccc;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  min-width: 17em;
}
